<template>
  <div class="all">
    <div class="search">
      <van-search
        v-model="value"
        placeholder="请输入搜索关键词"
        shape="round"
      />
    </div>
    <swiper class="swiper" :options="swiperOption" v-if="imageList.length" show-indicators="false">
      <swiper-slide v-for="item in imageList" :key="item.id">
        <img :src="item.image" alt="" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <van-swipe class="adv" vertical autoplay="3000">
      <van-swipe-item v-for="item in factoryList" :key="item.id">
        <div style="">
          <van-icon name="volume-o" color="#ee0a24" size="15" />
          <div class="news">最新招聘：{{ item.company }}招聘信息</div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <van-pull-refresh
      v-model="loading"
      @refresh="onRefresh"
      style="margin-bottom: 50px"
    >
    <div class="hot-list">
      <div class="hot">
        <h3><van-icon name="fire" color="#ee0a24" />&nbsp;&nbsp;热门推荐大厂超级适合你</h3>
      </div>
      <div v-for="item in factoryhostList" :key="item.id">
        <van-card
          style="margin-top: 0"
          :price="item.company"
          currency=""
          :desc="
            item.max_salary +
            '元' +
            '/' +
            item.salary_type_human
          "
          :title="item.company + '招聘信息'"
          :thumb="item.image"
          @click="toDetail(item.id)"
        >
          <template #tags>
            <van-tag plain type="danger" style="margin-right: 6px" v-if="item.tag">{{item.tag}}</van-tag>
            <van-tag plain type="danger" v-else>福利</van-tag>
            <br />
          </template>
          <template #footer>
            <van-button
              size="small"
              @click="toDetail(item.id)"
              type="info"
              icon="plus"
              >申请报名</van-button
            >
          </template>
        </van-card>
      </div>
    </div>
      <div class="recommend">
        <h3><van-icon name="star" />&nbsp;&nbsp;推荐的这些也很不错哦</h3>
      </div>
      <div v-for="item in factoryList" :key="item.id" >
        <van-card
          style="margin-top: 0"
          :price="item.company"
          currency=""
          :desc="
            item.max_salary +
            '元' +
            '/' +
            item.salary_type_human
          "
          :title="item.company + '招聘信息'"
          :thumb="item.image"
          @load="onLoad"
          @click="toDetail(item.id)"
        >
          <template #tags>
            <van-tag plain type="danger" style="margin-right: 6px" v-if="item.tag">{{item.tag}}</van-tag>
            <van-tag plain type="danger" v-else>福利</van-tag>
            <br />
            <!--             <span style="margin-top:10px;">{{item.company}}</span> -->
          </template>
          <template #footer>
            <van-button
              size="small"
              @click="toDetail(item.id)"
              type="info"
              icon="plus"
              >申请报名</van-button
            >
          </template>
        </van-card>
      </div>
      <van-pagination
      v-model="currentPage"
      :total-items="total"
      :show-page-size="10"
      force-ellipses
      @change="changeList"
      class="pagination"
    />
    </van-pull-refresh>
    <tab-bar></tab-bar>
  </div>
</template>

<script>
import { factoryList } from "network/others";
import { ImageList } from "network/others";
import {hostList} from "network/others";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import TabBar from "common/navbar/TabBar";
import {
  Button,
  Card,
  Pagination,
  PullRefresh,
  Tag,
  Toast,
  Swipe,
  SwipeItem,
  Icon,
  Search,
} from "vant";
export default {
  components: {
    swiper,
    swiperSlide,
    TabBar,
    [Button.name]: Button,
    [Card.name]: Card,
    [Pagination.name]: Pagination,
    [PullRefresh.name]: PullRefresh,
    [Tag.name]: Tag,
    [SwipeItem.name]: SwipeItem,
    [Swipe.name]: Swipe,
    [Icon.name]: Icon,
    [Search.name]: Search,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      list: [],
      loading: false,
      refreshing: false,
      currentPage: 1,
      factoryList: "",
      factoryhostList:"",
      imageList: "",
      total: "",
      page: "",
      value: "",
    };
  },
  mounted() {
    this.getFactoryList();
    this.getImageList();
    this.getHostList();
  },
  methods: {
    onLoad() {},
    onRefresh() {
      setTimeout(() => {
        this.getFactoryList();
        this.getHostList();
        Toast("刷新成功");
        this.loading = false;
        this.count++;
      }, 1000);
    },
    //工厂列表
    getFactoryList() {
      factoryList(this.page).then((res) => {
        this.factoryList = res.data.data;
        this.total = res.data.total;
      });
    },
    //热门工厂列表
    getHostList(){
      hostList().then((res)=>{
        this.factoryhostList = res.data;
      })
    },
    //轮播图列表
    getImageList() {
      ImageList().then((res) => {
        this.imageList = res.data;
      });
    },
    toDetail(id) {
      this.$router.push({
        path: "/work-detail",
        query: { id: id },
      });
    },
    changeList() {
      this.page = this.currentPage;
      this.getFactoryList();
    },
  },
};
</script>


<style scoped>
.all {
  font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑",
    Arial, sans-serif;
}
.swiper-container {
  width: 100%;
  height: 200px;
  margin-top: 50px;
  /* border-radius: 4%; */
}
.swiper-container img {
  width: 100%;
  height: 100%;
}
.van-card__title {
  font-size: 18px;
  line-height: 28px;
  height: 28px;
}
.van-card__desc {
  font-size: 13px;
  max-height: 26px;
  height: 26px;
  color: rgb(255, 172, 56);
}
.van-card__price-integer {
  font-size: 13px;
  margin-top: 4px;
}
.adv {
  height: 30px;
  line-height: 30px;
  margin: 10px 0 10px;
  padding-bottom: 4px;
  padding-left: 10px;
  font-size: 14px;
  background-color: #fff;
}
.search {
  height: 50px;
  width: 100%;
  position: fixed;
  z-index: 2000;
  padding-bottom: 50px;
  top: 0px;
}
/* .pagination {
  width: 100%;
  position: fixed;
  z-index: 2000;
  bottom: 0;
  background-color: #fff;
} */
.serve {
  position: absolute;
  top: 0;
  right: 20%;
  z-index: 2002;
}
.hot-list{
  margin-bottom: 10px;
}
.recommend, .hot{
  background-color:#fafafa;
  font-size: 15px;
  padding: 6px 0 6px 6px;
  color: rgb(255, 172, 56);
}
.news{
  padding-left: 20px;
}
.van-icon-volume-o{
  position: absolute;
  top: 28%;
}
</style>